.containerCard{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card{
  width: 250px;
  height: 250px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cardHomePage{
  width: 100%;
  border-radius: 8px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.titleCard{
  display: flex;
  justify-content: center;
}