.connect{
  background: rgb(255, 217, 0);
  padding: 1rem 2rem;
  border-radius: 20px;
  font-weight: 700;
  border: 0;
  cursor: pointer;
  width: 140px;
  margin-top: 0px;
  margin-right: 20px;
}