@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

.NavBar {
  display: flex;
  flex-direction: row;
  height: 100px;
  background-color: rgb(245, 159, 30);
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
}
.linkPageNav{
  display: flex;
  flex-direction: row;
}

.logo {
  margin-left: 50px;
  transition: all 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
}

.containerLinkPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.plinkPage {
  width: 200px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.plinkPage:hover {
  transform: translateY(-5px);
}

.HeaderLink {
  color: black;
  margin-right: 40px;
  text-decoration: none;
  transition: transform 2s ease-in-out;
  padding-bottom: 20px;
  font-weight: 500;
}

.HeaderLink:hover {
  font-size: 18px;
  transform: rotate(360deg);
  color: black;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .NavBar {
    flex-direction: column;
    height: auto;
    padding: 0px 0;
  }
  .connect{
    margin-top: 40px;
    margin-bottom: 20px;
    width: 50%;
    display: flex;
    justify-content: center;
    margin-left: 20px;
  }

  .logo {
    margin-left: 25%;
    margin-top: 20px;
    width: 50%;

  }
  .linkPageNav{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .containerLinkPage {
    flex-direction: column;
  }

  .plinkPage {
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }



  .HeaderLink {
    margin-right: 0;
  }
}
