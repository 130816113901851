

html {
  font-size: 16px;
}

body {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-family: 'Pacifico', cursive;
}

.count {
  font-size: 2rem;
  font-weight: 700;
}
.h2Container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDD7AF;
  width: 100%;

  .titleh2 {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin: 1rem 0;
  }
}








.cost {
  font-weight: 300;
  background-color: #f59f1e;
  border-radius: 16px;
  padding: 40px;
  box-shadow: rgba(11, 250, 241, 0.2) 0px 12px 28px 0px, rgba(6, 204, 181, 0.1) 0px 2px 4px 0px, rgba(5, 232, 179, 0.05) 0px 0px 0px 1px inset;

}

.nft-gallery{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

button {
  background:#F59F1E;
  padding: 1rem 2rem;
  border-radius: 20px;
  font-weight: 700;
  border: 0;
  cursor: pointer;
  width: 140px;
  margin-top: 10px;
}

.withdraw {
  margin-bottom: 50px;
}

.nftImage{
  width: 250px;
  height: 250px;
  border-radius: 8px;
}

.nft-card{
  display: flex;
  flex-direction: column;
  align-items:center;
  margin-left: 3%;
  margin-top: 20px;
}
.container{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5%;
  align-items: center;
  align-content: center;
  margin-top: 80px;
  //background-color: #EDD7AF;
  //border-radius: 8px;
}
.buttonMint{
  display: none;
}